import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { StyleSheetManager } from 'styled-components'

const shouldForwardProp = (prop) => prop !== 'sortDirection'

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <App />
    </StyleSheetManager>
  </Provider>,
)

reportWebVitals()
