import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked } from '@coreui/icons'
import BasicProvider from 'src/constants/BasicProvider'
import { useNavigate, useParams } from 'react-router-dom'

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
})
const SetPassword = () => {
  const { token } = useParams()
  const navigate = useNavigate()

  const HandleonSubmit = async (formdata) => {
    try {
      if (formdata.confirm_password === formdata.password) {
        const id = await new BasicProvider(`auth/reset-password/${token}`).postRequest(formdata)
        console.log(id)
        navigate('/login')
      } else {
        console.error('password not match')
      }
    } catch (error) {
      console.error(error)
    }
  }
  const handleSpaceKeyDown = (event) => {
    if (event.key === ' ') {
      event.preventDefault()
    }
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <Formik
                  initialValues={{
                    password: '',
                    confirm_password: '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={HandleonSubmit}
                >
                  <Form>
                    <h1>Change Password</h1>
                    <div className="mb-3">
                      <label htmlFor="password">Password</label>
                      <CInputGroup>
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <Field
                          type="password"
                          name="password"
                          className="form-control"
                          onKeyDown={handleSpaceKeyDown}
                        />
                      </CInputGroup>
                      <ErrorMessage name="password" component="div" className="text-danger" />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="confirm_password">Confirm Password</label>
                      <CInputGroup>
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <Field
                          type="password"
                          name="confirm_password"
                          className="form-control"
                          onKeyDown={handleSpaceKeyDown}
                        />
                      </CInputGroup>
                      <ErrorMessage
                        name="confirm_password"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="d-grid">
                      <CButton color="success" type="submit" name="submit">
                        Create Account
                      </CButton>
                    </div>
                  </Form>
                </Formik>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default SetPassword
